import React, { CSSProperties } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import "./Card.css";
import { ServicesData } from "../Api/types";

// interface  featuresCardData{
//   image:string;
//   heading:string;
//   cardPara:string;
// }
const paragraphStyles: CSSProperties = {
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
  overflow: "hidden",
  display: "-webkit-box",
};

interface featuresCardProps {
  data: ServicesData;
}

const featuresCard: React.FC<featuresCardProps> = ({ data }) => {
  return (
    <>
      {data && (
        <div className="features-card">
          <div className="features-card-img-box">
            <img src={data.icon} alt="" />
          </div>
          <h1>{data.title}</h1>
          <p style={paragraphStyles}>{data.description}</p>
          <div className="learn-more-service">
            <a href="/">
              {" "}
              <span className="primary-color">Learn more </span>
              <MdArrowForwardIos className="primary-color icon-font" />
            </a>
          </div>
        </div>
      )}
      {/*  */}
    </>
  );
};

export default featuresCard;
