import React, { SetStateAction, useState } from "react";
import { FaStar } from "react-icons/fa";
import { Link } from "react-router-dom";
// import { IoMdArrowForward } from 'react-icons/io'
import { BsCheckCircleFill } from "react-icons/bs";
import "../Home/Home.css";
import ratingsimg from "../Home/Home-images/ratings-img.png";
import ideometriximg from "../Home/Home-images/ideometrix-img2.png";
import customersImg from "../Home/Home-images/OBJECTS.png";

// import ErumPic from '../../Components/TestimonialSlider/erum.png'
// import KamalPic from '../../Components/TestimonialSlider/Kamalpic.png'

import Card from "../../Components/FeaturesCard/Card";
import ProductsCard from "../../Components/products/ProductsCard";
import TestimonialSlider from "../../Components/TestimonialSlider/TestimonialSlider";
import TalentGrowthPartners from "../../Components/TalentGrowthCards/TalentGrowthPartners";

import GBIT from "../Programs/TalentImages/gbit.png";
import AP from "../Programs/TalentImages/ap.png";
import AKRSP from "../Programs/TalentImages/akrsp.png";
import SCO from "../Programs/TalentImages/sco.png";
import OneA from "../Programs/TalentImages/one-a.png";
import APEX from "../Programs/TalentImages/apex.png";
import DoroyouInn from "../Programs/TalentImages/doroyou-inn.png";
import KIU from "../Programs/TalentImages/kiu.png";
import Meredith from "../Programs/TalentImages/meredith-p.png";
import Tmi from "../Programs/TalentImages/tmi.png";
import Journal from "../Programs/TalentImages/journal.png";
import Stenovate from "../Programs/TalentImages/stenovate.png";
import AlWaliyy from "../Programs/TalentImages/al-waliyy.png";
import Tennis from "../Programs/TalentImages/tennis.png";
import Arctica from "../Programs/TalentImages/arctica.png";
import MoonAlgo from "../Programs/TalentImages/moon-algo.png";
import Rupani from "../Programs/TalentImages/rupani.png";
import BTF from "../Programs/TalentImages/btf.png";
import TheKarakoram from "../Programs/TalentImages/the-karakoram.png";
import GDG from "../Programs/TalentImages/gdg.png";
import KamalEnergy from "../Programs/TalentImages/kamal-energy.png";
import GbInvest from "../Programs/TalentImages/gb-invest.png";
import Dum from "../Programs/TalentImages/dum.png";
import Olright from "../Programs/TalentImages/olright.png";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./App.css";

// import required modules
import { Autoplay, Navigation, Keyboard, Scrollbar, Pagination } from "swiper";

import { homeCardData, productData, testimonialData } from "./homeData";
import MetaData from "../../Components/MetaData";

import CoursesModal from "../../Components/CoursesModal/CoursesModal";
import { useQuery } from "@tanstack/react-query";
import {
  getAllPortfolio,
  getAllServices,
  getAllTestimonials,
  getAllWorkCategory,
} from "../../Components/Api/PostServices";
import {
  Category,
  Portfolio,
  ServicesData,
  TestimonialData,
} from "../../Components/Api/types";
import HomeRecentWork from "../../Components/HomeRecentWork/HomeRecentWork";

interface HomeProps {
  showModal: boolean;
  setShowModal: React.Dispatch<boolean>;
}
const Home: React.FC<HomeProps> = ({ showModal, setShowModal }) => {
  const [selectedCategory, setSelectedCategory] = useState<string | null>(
    "Cooperate Branding"
  );

  const handleCategoryChange = (categoryName: string) => {
    setSelectedCategory(categoryName);
  };

  //get all services data
  const { data } = useQuery<ServicesData[]>({
    queryFn: getAllServices,
    queryKey: ["services"],
  });

  const { data: clientData } = useQuery<TestimonialData[]>({
    queryFn: getAllTestimonials,
    queryKey: ["testimonials"],
  });

  const { data: categoryData } = useQuery<Category[]>({
    queryFn: getAllWorkCategory,
    queryKey: ["category"],
  });
  const { data: portfolioData } = useQuery<Portfolio[]>({
    queryFn: getAllPortfolio,
    queryKey: ["portfolio"],
  });
  const filteredPortfolio =
    selectedCategory && portfolioData
      ? portfolioData.filter(
          (portfolio) => portfolio.cateogry_id.name === selectedCategory
        )
      : portfolioData;
  return (
    <>
      <MetaData title={"Home - Ideometrix"} />
      {/* -------------Ideometrix Section -------------- */}

      <>
        {showModal ? (
          <CoursesModal showModal={showModal} setShowModal={setShowModal} />
        ) : (
          ""
        )}

        <section className="imx-section">
          <div className="max-width-1440">
            <div className="imx">
              <div className="imx-left">
                <span className="gb">
                  Gilgit Baltistan's First Design and Tech Studio
                </span>
                <div className="heading-rating">
                  <h1>
                    {" "}
                    <span className="primary-color">Ideo</span>Metri
                    <span className="primary-color">X</span> provides Digital
                    Consultancy and Software Development
                  </h1>
                  <div className="rating-details">
                    <img src={ratingsimg} alt="" />
                    <div className="clients-ratings">
                      <p className="happy-client">+12k Happy Clients</p>
                      <div className="clients-ratings-bottom">
                        <FaStar className="primary-color" />
                        <span className="primary-color">4.9</span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <p className='imx-left-para'>We examine the top of funnel to figure out how to make people to a subscribe newsletter then turn prospects into leads.</p> */}
                <p className="imx-left-para">
                  We assist businesses in building their online presence by
                  offering Digital Consultancies and software solutions.
                </p>
                <div className="imx-btn-pricing">
                  <Link to={"/contact"}>
                    <button className="imx-contact-btn">Contact Us</button>
                  </Link>
                  {/* <div className="imx-pricing">
                    <span className='primary-color'>Our pricing</span>
                    <IoMdArrowForward className='primary-color imx-pricing-icon' />
                  </div> */}
                  <Link to={"/about"}>
                    {" "}
                    <button className="imx-learnMore">Learn More</button>{" "}
                  </Link>
                </div>
              </div>
              <div className="imx-right">
                <img loading="lazy" src={ideometriximg} alt="" />
              </div>
            </div>
          </div>
        </section>

        {/* ------------ Features Section ------------ */}

        <section className="features-section">
          <div className="max-width-1440">
            <div className="features">
              <span className="features-span primary-color">Our Services</span>
              <h1 className="features-heading">
                Redefine Success with Innovative Solutions
              </h1>
              {data && data.length > 0 ? (
                <div className="features-cards">
                  {data.map((services, i) => (
                    <Card data={services} key={i} />
                  ))}
                </div>
              ) : (
                <p style={{textAlign:'center'}}>no data to show</p>
              )}
              <div className="features-cards-slider">
                <Swiper
                  breakpoints={{
                    970: {
                      slidesPerView: 2,
                    },
                    800: {
                      slidesPerView: 2,
                    },
                    350: {
                      slidesPerView: 2,
                      spaceBetween: 0,
                    },
                  }}
                  slidesPerView={2}
                  spaceBetween={30}
                  loop={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  keyboard={{
                    enabled: true,
                  }}
                  modules={[Autoplay, Keyboard, Scrollbar]}
                  className="mySwiper"
                >
                  {data && data.length > 0 ? (
                    <div>
                      {data.map((data, i) => (
                        <SwiperSlide>
                          {" "}
                          <Card data={data} key={i} />
                        </SwiperSlide>
                      ))}
                    </div>
                  ) : (
                    <p>no services to show</p>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </section>

        {/* ---------- Customers Section ------------ */}

        <section className="customers-section">
          <div className="max-width-1440">
            <div className="customers">
              <div className="customers-left">
                <img src={customersImg} alt="" />
              </div>
              <div className="customers-right">
                <h1 className="customers-heading">
                  Customer support is our main priority
                </h1>
                <p className="customers-para">
                  We are dedicated to delivering exceptional customer support
                  and ensuring 100% satisfaction. We believe that by providing
                  our customers with outstanding service, we can build strong,
                  long-lasting relationships that benefit everyone involved
                </p>
                <div className="customers-values">
                  <div>
                    <BsCheckCircleFill className="primary-color" />{" "}
                    <span>Customer Service </span>
                  </div>
                  <div>
                    <BsCheckCircleFill className="primary-color" />{" "}
                    <span>Quality Service</span>
                  </div>
                  <div>
                    <BsCheckCircleFill className="primary-color" />{" "}
                    <span>Competitive Pricing</span>
                  </div>
                  <div>
                    <BsCheckCircleFill className="primary-color" />{" "}
                    <span>Timely delivery</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* -------------- Products Section --------------- */}

        <section className="products-section">
          <div className="max-width-1440">
            <div className="products">
              <span className="products-span primary-color">Our Products</span>
              <h1 className="products-heading">Meet our dynamic team</h1>
              <div className="products-cards">
                {productData.map((p, i) => (
                  <ProductsCard data={p} key={i} />
                ))}
              </div>
            </div>
          </div>
        </section>

        {/* ------------------ Recent Work Section -------------- */}

        <section className="recent-work-section">
          <div className="max-width-1440">
            <div className="recent-work">
              <h1 className="recent-work-heading">
                Our<span className="primary-color"> Latest</span> Work
              </h1>
              <p className="recent-work-para">
                Discover and learn more about our latest works
              </p>
              {categoryData && categoryData.length > 0 ? (
                <div className="recent-work-pages">
                  {categoryData.map((category) => (
                    <span
                      key={category._id}
                      className={`recent-work-span ${
                        selectedCategory === category.name
                          ? "active-category-work"
                          : ""
                      }`}
                      onClick={() => {
                        handleCategoryChange(category.name);
                      }}
                    >
                      {category.name}
                    </span>
                  ))}
                </div>
              ) : (
                <p>no work category to show</p>
              )}
              <div className="recent-work-images">
                {filteredPortfolio && filteredPortfolio.length > 0 ? (
                  filteredPortfolio.map((work) => (
                    <div key={work._id}>
                      <HomeRecentWork data={work} />
                    </div>
                  ))
                ) : (
                  <p>no data to show</p>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* -------------- Testimonial Section --------------- */}

        <section className="testimonials-section">
          <div className="max-width-1440">
            <div className="testimonials">
              <h1 className="testimonials-heading">
                What Our <span className="primary-color">Clients</span> Say
              </h1>
              <p className="testimonials-para">
                Get to know about our clients and what they say about our
                service.
              </p>
              <div className="home-testimonial-slider">
                {clientData && clientData.length > 0 ? (
                  <Swiper
                    spaceBetween={50}
                    loop={true}
                    centeredSlides={true}
                    pagination={{
                      clickable: true,
                      // dynamicBullets: true,
                    }}
                    autoplay={{
                      delay: 3000,
                      disableOnInteraction: false,
                      pauseOnMouseEnter: true,
                    }}
                    navigation={true}
                    modules={[
                      Autoplay,
                      Pagination,
                      Navigation,
                      Scrollbar,
                      Keyboard,
                    ]}
                    className="mySwiper"
                  >
                    
                    {clientData.map((reviews, i) => (
                      <SwiperSlide>
                        {" "}
                        <TestimonialSlider data={reviews} key={i} />{" "}
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  <p>no testimonials to show</p>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* ---------- Companies Section ------------- */}

        <section className="companies-section">
          <div className="max-width-1440">
            <div className="companies">
              <h1 className="companies-heading">
                <span className="primary-color">Company</span> we have worked
              </h1>

              <div className="compaines-slider">
                <Swiper
                  breakpoints={{
                    970: {
                      slidesPerView: 5,
                    },
                    800: {
                      slidesPerView: 5,
                    },
                    350: {
                      slidesPerView: 3,
                      spaceBetween: 0,
                    },
                  }}
                  spaceBetween={50}
                  loop={true}
                  centeredSlides={true}
                  autoplay={{
                    delay: 3000,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Navigation, Scrollbar, Keyboard]}
                  className="mySwiper talent-parthers-slider"
                >
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={GBIT} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={AP} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={AKRSP} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={SCO} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={DoroyouInn} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={OneA} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={APEX} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={KIU} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Meredith} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Tmi} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Journal} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Stenovate} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={AlWaliyy} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Tennis} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Arctica} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={MoonAlgo} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Rupani} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={BTF} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={TheKarakoram} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={GDG} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={KamalEnergy} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={GbInvest} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Dum} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <TalentGrowthPartners talentPartnerLogo1={Olright} />
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default Home;
