import React from 'react'
import './DigiHeroCard.css'

interface DigiHeroCardProps {
  cardHeading: string,
  cardImg1:string, 
  cardImg2: string, 
  cardImg3: string, 
  cardImg4: string,
}

const DigiHeroCard:React.FC<DigiHeroCardProps> = ({cardHeading, cardImg1, cardImg2, cardImg3, cardImg4}) => {
  return (
    <>
    <div className="digi-hero-card">
        <h1 className="digi-hero-card-head">{cardHeading}</h1>
        <div className="digi-hero-card-bottom">
            <img src={cardImg1} alt="" className="digi-hero-card-img1" />
            <img src={cardImg2} alt="" className="digi-hero-card-img2" />
            <img src={cardImg3} alt="" className="digi-hero-card-img3" />
            <img src={cardImg4} alt="" className="digi-hero-card-img4" />
        </div>

    </div>
    </>
  )
}

export default DigiHeroCard