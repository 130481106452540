import React from 'react'
import '../TestimonialSlider/TestimonialSlider.css'
import { TestimonialData } from '../Api/types'
import sliderQouts from '../TestimonialSlider/double-qouts.png'

interface TestimonialSliderProps{
    data: TestimonialData;

}


const TestimonialSlider:React.FC<TestimonialSliderProps> = ({ data }) => {
    return (
        <>
            <div className="testimonial-sliders">
                <div className="testimonial-slider1">
                    <img className='testimonial-qout' src={sliderQouts} alt="" />
                    <div className="testimonial-img-outer">
                        <img className='testimonial-img' src={data.imageUrl} alt="" />
                    </div>
                    <p className="testimonial-slider-para">{data.description}</p>
                    <div className="testimonial-slider-bottom">
                        <p className="testimonial-p primary-color">{data.clientName}</p>
                        <p className='testimonial-bottom-p'>{data.designation}</p>
                    </div>

                </div>
            </div>
        </>
    )
}

export default TestimonialSlider