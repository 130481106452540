import React from 'react'
import '../TalentGrowthCards/InternsCard.css'

interface InternsCardProps{
    internImg:string, 
    internPosition: string, 
    internName: string, 
    internBatchNo: string
}

const InternsCard:React.FC<InternsCardProps> = ({ internImg, internPosition, internName, internBatchNo }) => {
    return (
        <>
            <div className="intern-card">
                <img src={internImg} alt="" />
                <div className="intern-details">
                    <div className="intern-batch">
                        <span className="intern-batch-no">{internBatchNo}</span>
                    </div>
                    <div className="intern-head-detail">
                        <h1 className="intern-detail-heading">{internPosition}</h1>
                        <p className="intern-detail-para">{internName}</p>
                    </div>
                </div>
            </div>
        </>
    )
}

export default InternsCard