// Get all Services
import { Instance } from "./Api";
import {
  CareerData,
  Category,
  EmployesData,
  Portfolio,
  ServicesData,
  TestimonialData,
} from "./types";

export async function getAllServices(): Promise <ServicesData[]>
 {
  try {
    const response = await Instance.get(`/our-services/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    console.log(response);
    return response.data.result.items;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || error.message || "Failed to Get services"
    );
  }
}

export async function getAllEmployees(): Promise<EmployesData[]>{
  try {
    const response = await Instance.get(`/employee`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.result.items;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        "Failed to Get Employees"
    );
  }
}

export async function getAllTestimonials(): Promise<TestimonialData[]> {
  try {
    const response = await Instance.get(`/testimonials`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.result.items;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        "Failed to Get Employees"
    );
  }
}
//portfolio

export async function getAllPortfolio(): Promise<Portfolio[]> {
  try {
    const response = await Instance.get(`/portfolio`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.result.items;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        "Failed to Get portfoliio"
    );
  }
}

//work category

export async function getAllWorkCategory(): Promise<Category[]>
{
  try {
    const response = await Instance.get(`/WorkCategory/`, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data.result.items;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || error.message || "Failed to Get Category"
    );
  }
}

export async function postFormData(formdata: Record<string , any>): Promise<any> {
  try {
    const response = await Instance.post ('/contactus',formdata,{
      headers:{
        "Content-Type":'application/json',
      }
    })
    return response.data;
  } catch (error: any) {
    throw new Error(
      error.response?.data?.message || error.message || "Failed submit form"
    );
  }
  
}


export async function GetAllCareers():Promise<CareerData[]> {
  try {
    const response = await Instance.get(`/career`,
      {
        headers:{
          'Content-Type':'application/json'
        }
      }
    );
    return response.data.result.items;
  } catch (error:any) {
    throw new Error(
       error.response?.data?.message || error.message || "Failed to Get Career"
    )
  }
  
}

// ADD Applicants
export async function AddApplicant(newApplicant: FormData): Promise<any> {
  try {
    const response = await Instance.post(`/applicant`, newApplicant, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data.result.items;
  } catch (error: any) {
    console.log(error)
    throw new Error(
      error.response?.data?.message ||
        error.message ||
        'Failed to Add applicant',
    );
  }
}