import React from 'react'
import './TalentGrowthPartners.css'

interface TalentGrowthPartnersProps{
  talentPartnerLogo1: string
}

const TalentGrowthPartners:React.FC<TalentGrowthPartnersProps> = ({ talentPartnerLogo1 }) => {
  return (
    <>
    <div className="talent-partners-logo-div">
      <img src={talentPartnerLogo1} alt="" className="talent-growth-partner-img"/>
    </div>
    </>
  )
}

export default TalentGrowthPartners