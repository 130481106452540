import React from 'react'
import { Link, useLocation } from "react-router-dom";
import '../Footer/Footer.css'
// Footer Images
import Logo from '../../Components/Footer/logo.svg'
import Fb from '../../Components/Footer/fb.svg'
import LinkedIn from '../../Components/Footer/linkedIn.svg'
import Instagram from '../../Components/Footer/insta.svg'
import Phone from '../../Components/Footer/phone.svg'
import Mail from '../../Components/Footer/mail.svg'
import Address from '../../Components/Footer/address.svg'
import IMXlogo from '../Footer/IMX2.svg'

import FacebookLogo from '../Footer/fb1.svg'
import LinkedInLogo from '../Footer/linkedin1.svg'
import InstaLogo from '../Footer/insta1.svg'

const Footer = () => {
  const params = useLocation()
  return (
    <>
      <footer className="footer-section" >
        <div className="max-width-1440">
          <div className="footer">
            <div className="footer-left">
              <img src={Logo} alt="" className='imx-logo1' />
              <img src={IMXlogo} alt="" className='imx-logo2' />
              <p className="footer-left-para">Don't wait any longer to take your business to the next level. Contact us now to see how we can help you achieve your goals! Let's start the conversation today.
              </p>
              <div className="footer-social-icons">
                <a href="https://www.facebook.com/search/top?q=ideometrix" target="_blank" rel="noreferrer"><img src={Fb} alt="" /></a>
                <a href="https://www.linkedin.com/company/ideometrix/" target="_blank" rel="noreferrer"><img src={LinkedIn} alt="" /></a>
                <a href="https://instagram.com/ideometrix?igshid=NDk5N2NlZjQ=" target="_blank" rel="noreferrer"><img src={Instagram} alt="" /></a>
              </div>
            </div>
            <div className="footer-middle">
              <div className="footer-middle-links">
                <span className="footer-middle-span">Links</span>
                <Link to="/about">About Us</Link>
                <Link to="/contact">Contact Us</Link>
                <Link to="/careers">Careers</Link>
              </div>
              <div className="footer-middle-links">
                <span className="footer-middle-span">Programs</span>
                <Link to="/programs/stepup">Step Up</Link>
                <Link to="/programs/talent-growth">Talent Growth </Link>
              </div>
              <div className="footer-middle-links">
                <span className="footer-middle-span">Products</span>
                <Link to="/products/digihunar">DigiHunar</Link>
                <Link to="/products/hotela">Hotela</Link>
              </div>
            </div>
            <div className="footer-right">
              <div className="footer-right-icons-details">
                <img src={Phone} alt="" />
                <div className="footer-right-details">
                  <span className='footer-contacts'>Phone</span>
                  <span>+92 315 5687893</span>
                </div>
              </div>
              <div className="footer-right-icons-details">
                <img src={Mail} alt="" />
                <div className="footer-right-details">
                  <span className='footer-contacts'>Mail</span>
                  <span className='mt-3'>info@ideometrix.com</span>
                </div>
              </div>
              <div className="footer-right-icons-details">
                <img src={Address} alt="" />
                <div className="footer-right-details translateY-5">
                  <span className='footer-contacts'>Address</span>
                  <span className='mt-3'>IdeoMetriX Office near Army Helipad Sonikot Gilgit.</span>
                </div>
              </div>
            </div>
            {/* ------ this code is for mobile responsive -------*/}
            <div className='breakline'></div>
            <span className="about-contact-career-links">Links</span>
            <div className="about-career-contact-list">
              {/* <Link to="/about">About Us</Link> */}
              {
                params.pathname === '/about' 
              }
              <li><Link to="/about" className={`${params.pathname === '/about' ? 'active' : ''}`} id={`${params.pathname === '/about' ? 'primary-color' : ''}`}>About Us</Link></li>
              {
                params.pathname === '/contact' 
              }
              <li><Link to="/contact" className={`${params.pathname === '/contact' ? 'active' : ''}`} id={`${params.pathname === '/contact' ? 'primary-color' : ''}`}>Contact Us</Link></li>

              {/* <Link to={'/contact'}>Contact Us</Link> */}

              {
                params.pathname === '/careers' 
              }
              <li><Link to="/careers" className={`${params.pathname === '/careers' ? 'active' : ''}`} id={`${params.pathname === '/careers' ? 'primary-color' : ''}`}>Careers</Link></li>
              {/* <Link to="/careers">Careers</Link> */}
            </div>
            <div className="social-media-icons-bottom">
              <a href="https://www.facebook.com/search/top?q=ideometrix" target="_blank" rel="noreferrer"><img src={FacebookLogo} alt="" /></a>
              <a href="https://www.linkedin.com/company/ideometrix/" target="_blank" rel="noreferrer"><img src={LinkedInLogo} alt="" /></a>
              <a href="https://instagram.com/ideometrix?igshid=NDk5N2NlZjQ=" target="_blank" rel="noreferrer"><img src={InstaLogo} alt="" /></a>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <span>Ideometrix© 2023, All Rights Reserved</span>
        </div>
      </footer>
    </>
  )
}

export default Footer