import React from "react";
import "../EmployeesCards/EmployeeCard.css";
import linkedInIcon from "../../pages/About/About-Imagess/linkedin.png";
import upworkIcon from "../../pages/About/About-Imagess/pngegg (1).png";
import { EmployesData } from "../Api/types";

// interface EmployeeCardData{
//   employeeImage:string;
//   employeeName:string;
//   employeePosition: string;
//   link:string
// }
interface EmployeeCardProps {
  data: EmployesData;
}
const EmployeeCard: React.FC<EmployeeCardProps> = ({ data }) => {
  return (
    <>
      <div className="employee-card">
        <img className="employee-img" src={data.imageUrl} alt="" />
        <span className="employee-name">{data.name}</span>
        <span className="employee-position">{data.designation}</span>
        <div className="employee-social-account">
          <span className="followat">Follow at</span>
          <a href={data.linkdInProfileUrl} target="_blank" rel="noreferrer">
            <img className="linkedin-img" src={linkedInIcon} alt="" />
          </a>
          <a href={data.upworkProfileUrl} target="_blank" rel="noreferrer">
            <img className="upwork-img" src={upworkIcon} alt="" />
          </a>
        </div>
      </div>
    </>
  );
};

export default EmployeeCard;
