import React, { useState, useRef } from 'react'
import { Link, useLocation } from "react-router-dom";
import Logo from '../Navbar/logo.svg'
import CloseButton from '../Navbar/close-circle.png'
import '../Navbar/Navbar.css'
import { RiArrowDropDownLine } from 'react-icons/ri'
// import { HiChevronDown } from 'react-icons/hi'
import { HiOutlineChevronDown } from 'react-icons/hi'
// import { BsDot } from 'react-icons/bs'



const Navbar:React.FC = () => {

  const params = useLocation()
  // console.log(params.pathname === '/')

  // const clickCloseBtn = () => {

  // }
  const [nav, setNav] = useState<boolean>(false)
  const navRef = useRef<HTMLUListElement>(null)

  const productRef = useRef<HTMLDivElement>(null)
  const programRef = useRef<HTMLDivElement>(null)

  const handleProductDrop = () => {
    if(productRef.current){
      if (productRef.current.style.maxHeight.length === 0 || productRef.current.style.maxHeight === '0px') {
        productRef.current.style.maxHeight = '100px'
        if(programRef.current)
        programRef.current.style.maxHeight = '0px'
      } else {
        productRef.current.style.maxHeight = '0px'
      }

    }
  }
  const handleProgramDrop = () => {
    if(programRef.current){
      if (programRef.current.style.maxHeight.length === 0 || programRef.current.style.maxHeight === '0px') {
        programRef.current.style.maxHeight = '100px'
        if(productRef.current)
        productRef.current.style.maxHeight = '0px'
      } else {
        programRef.current.style.maxHeight = '0px'
      }

    }
  }
  const handleNavDrop = () => {
    if(navRef.current){

      if (navRef.current.style.maxHeight.length === 0 || navRef.current.style.maxHeight === '0px') {
        setNav(true)
        navRef.current.style.maxHeight = '200px'
      } else {
        setNav(false)
        navRef.current.style.maxHeight = '0px'
      }
    }
  }

  const handleNav = () => {
    setNav(false)
    if(navRef.current)
    navRef.current.style.maxHeight = '0px'
  }
  return (



    <>

      <header>
        <div className="header-inner">
          <nav>
            <div className="logo"> <Link to="/"><img src={Logo} alt="" /></Link> </div>
            <div className="list-btn">
              <ul>
                <div className='link-drop' >
                  {/* {
                    params.pathname === '/' && (
                      <BsDot className={`${params.pathname === '/' ? 'drop-icon' : ''}`} id='primary-color' />
                    )
                  } */}
                  <li ><Link className={`${params.pathname === '/' ? 'active' : ''}`} id={`${params.pathname === '/' ? 'primary-color' : ''}`} to="/">Home</Link></li>
                </div>
                <div className='link-drop'>
                  <li onClick={handleNav}><Link to="/about" className={`${params.pathname === '/about' ? 'active' : ''}`} id={`${params.pathname === '/about' ? 'primary-color' : ''}`}>About Us</Link></li>
                </div>               

                <div className='link-drop drop'>
                  <div className='programs-options'>
                    <li className={`${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'active-li' : ''}`} id={`${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'primary-color' : ''}`}>Our Programs</li>
                    <RiArrowDropDownLine className={`arrow-down ${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'drop-icon' : 'drop-icon'}`} id={`${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'primary-color' : ''}`} />
                  </div>
                  <div className="products-drop drop-down">
                    <div className="dropdown-arrow"></div>

                    <div className="dropdown-links">
                      <Link onClick={handleNav} to="/programs/stepup" className={`drop-link ${params.pathname === '/programs/stepup' ? 'active primary-style' : ''}`} id={`${params.pathname === '/programs/stepup' ? 'primary-color' : ''}`}>StepUp Ademia</Link>
                      <Link onClick={handleNav} to="/programs/talent-growth" className={`drop-link ${params.pathname === '/programs/talent-growth' ? 'active primary-style' : ''}`} id={`${params.pathname === '/programs/talent-growth' ? 'primary-color' : ''}`}>Talent Growth</Link>

                    </div>

                  </div>
                </div>

                <div className='link-drop drop'>

                  <div className='products-options'>
                    <li className={`${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'active-li' : ''}`} id={`${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'primary-color' : ''}`}>Our Products</li>
                    <RiArrowDropDownLine className={`arrow-down ${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'drop-icon' : 'drop-icon'}`} id={`${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'primary-color' : ''}`} />
                  </div>
                  <div className="products-drop drop-down">
                    <div className="dropdown-arrow"></div>
                    <div className="dropdown-links">
                      <Link onClick={handleNav} to="/products/digihunar" className={`drop-link ${params.pathname === '/products/digihunar' ? 'active primary-style' : ''}`} id={`${params.pathname === '/products/digihunar' ? 'primary-color' : ''}`}>DigiHunar</Link>
                      <Link onClick={handleNav} to="/products/hotela" className={`drop-link ${params.pathname === '/products/hotela' ? 'active primary-style' : ''}`} id={`${params.pathname === '/products/hotela' ? 'primary-color' : ''}`}>Hotela</Link>

                    </div>
                  </div>
                </div>
                
              </ul>
              <Link to={'/contact'}>
                <button className="btn">Contact Us</button>
              </Link>
            </div>
          </nav>
        </div>
      </header>

      {/* Responsive Navbar */}

      <div className="navbar-2-div">
        <div className="navbar-2" >
          <Link to="/"><img src={Logo} alt="" className="small-logo" /></Link>
          {
            nav && (
              <img src={CloseButton} alt="" className='close-btn' onClick={handleNavDrop} />
            )
          }
          {
            !nav && (
              <div className="hamburger" onClick={() => handleNavDrop()}>
                <div className="ham-div-1"></div>
                <div className="ham-div-2"></div>
                <div className="ham-div-3"></div>
              </div>
            )
          }
        </div>
        <ul className={`nav-list`} ref={navRef} >
          <div className="nav-list-icon" style={{ marginBottom: '10px' }} onClick={handleNav}>
            {params.pathname === '/'}
            <li><Link className={` ${params.pathname === '/' ? 'li-primary-style' : 'normal-style'}`} id={`${params.pathname === '/' ? 'primary-color' : ''}`} to="/">Home</Link></li>

            {/* <li><a href="#">Home</a></li> */}
          </div>

          <div className="nav-list-icon" onClick={handleNav}>

            {
              (params.pathname === '/about')
            }
            <li className='extra-margin'><Link to="/about" className={`${params.pathname === '/about' ? 'li-primary-style' : 'normal-style'}`} id={`${params.pathname === '/about' ? 'primary-color' : ''}`}>About Us</Link></li>
          </div>
         
          <div className="nav-list-icon" onClick={handleProgramDrop}>

            {
              (params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth')
            }
            <li className={`links-style ${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'li-primary-style' : ''}`} id={`${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'primary-color' : ''}`}>Our Programs</li>
            <HiOutlineChevronDown className={`arrow-down ${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'drop-icon' : 'drop-icon'}`} id={`${params.pathname === '/programs/stepup' || params.pathname === '/programs/talent-growth' ? 'primary-color' : ''}`} />
          </div>
          <div className="program-dropdown product-dropdown" ref={programRef}>
            <Link onClick={handleNav} to="/programs/stepup" className={`${params.pathname === '/programs/stepup' ? 'a-primary-style' : ''}`} id={`${params.pathname === '/programs/stepup' ? 'primary-color' : ''}`}>Step Up</Link>
            <Link onClick={handleNav} to="/programs/talent-growth" className={`${params.pathname === '/programs/talent-growth' ? 'a-primary-style' : ''}`} id={`${params.pathname === '/programs/talent-growth' ? 'primary-color' : ''}`}>Talent Growth</Link>
          </div>
          <div className="nav-list-icon" onClick={handleProductDrop}>
            {
              (params.pathname === '/products/digihunar' || params.pathname === '/products/hotela')
            }
            <li className={`links-style ${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'li-primary-style' : ''}`} id={`${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'primary-color' : ''}`}>Our Products</li>
            <HiOutlineChevronDown className={`arrow-down ${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'drop-icon' : 'drop-icon'}`} id={`${params.pathname === '/products/digihunar' || params.pathname === '/products/hotela' ? 'primary-color' : ''}`} />
          </div>
          <div className="product-dropdown" ref={productRef} >
            <Link onClick={handleNav} to="/products/digihunar" className={`${params.pathname === '/products/digihunar' ? 'a-primary-style' : ''}`} id={`${params.pathname === '/products/digihunar' ? 'primary-color' : ''}`}>DigiHunar</Link>
            <Link onClick={handleNav} to="/products/hotela" className={`${params.pathname === '/products/hotela' ? 'a-primary-style' : ''}`} id={`${params.pathname === '/products/hotela' ? 'primary-color' : ''}`}>Hotela</Link>
          </div>
          
        </ul>
      </div>

    </>
  )
}

export default Navbar