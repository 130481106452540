import React from 'react'
import '../Programs/TalentGrowth.css'
import HeroSectionImg from '../Programs/TalentImages/heroImg.png'
import TalentGrowthLogo from '../Programs/TalentImages/talentGrowthLogo.svg'
import TalentImg1 from '../Programs/TalentImages/img1.png'

import GBIT from '../Programs/TalentImages/gbit.png'
import AP from '../Programs/TalentImages/ap.png'
import AKRSP from '../Programs/TalentImages/akrsp.png'
import SCO from '../Programs/TalentImages/sco.png'
import OneA from '../Programs/TalentImages/one-a.png'
import APEX from '../Programs/TalentImages/apex.png'
import DoroyouInn from '../Programs/TalentImages/doroyou-inn.png'
import KIU from '../Programs/TalentImages/kiu.png'
import Meredith from '../Programs/TalentImages/meredith-p.png'
import Tmi from '../Programs/TalentImages/tmi.png'
import Journal from '../Programs/TalentImages/journal.png'
import Stenovate from '../Programs/TalentImages/stenovate.png'
import AlWaliyy from '../Programs/TalentImages/al-waliyy.png'
import Tennis from '../Programs/TalentImages/tennis.png'
import Arctica from '../Programs/TalentImages/arctica.png'
import MoonAlgo from '../Programs/TalentImages/moon-algo.png'
import Rupani from '../Programs/TalentImages/rupani.png'
import BTF from '../Programs/TalentImages/btf.png'
import TheKarakoram from '../Programs/TalentImages/the-karakoram.png'
import GDG from '../Programs/TalentImages/gdg.png'
import KamalEnergy from '../Programs/TalentImages/kamal-energy.png'
import GbInvest from '../Programs/TalentImages/gb-invest.png'
import Dum from '../Programs/TalentImages/dum.png'
import Olright from '../Programs/TalentImages/olright.png'

import InternsCard from '../../Components/TalentGrowthCards/InternsCard'
import InternImg1 from './TalentImages/internImg1.png'
import InternImg2 from './TalentImages/internImg2.png'
import InternImg3 from './TalentImages/internImg3.png'
import InternImg4 from './TalentImages/internImg4.png'
import TalentGrowthCard from '../../Components/TalentGrowthCards/TalentGrowthCard'
import CertificationsImg from './TalentImages/talentGrowthcard-1.png'
import InternshipImg from './TalentImages/talentGrowthcard-2.png'
import ProjectsImg from './TalentImages/talentGrowthcard-3.png'
import CoursesImg from './TalentImages/talentGrowthcard-4.png'
import TraineesImg from './TalentImages/traneesLeft.png'
import TalentGrowthTraineeCard from '../../Components/TalentGrowthCards/TalentGrowthTraineeCard'
import TalentGrowthPartners from '../../Components/TalentGrowthCards/TalentGrowthPartners'
import { Link } from 'react-router-dom'



// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../Programs/TalentGrowth.css";

// import required modules
import { Autoplay, Navigation } from "swiper";
import MetaData from '../../Components/MetaData'
// import { useEffect } from 'react'



const TalentGrowth = () => {

  // useEffect(()=>{
  //   document.querySelectorAll('.swiper-slide').forEach((e)=>{
  //     e.style.maxWidth='220px'
  //   })
  // },[])

  return (
    <>
    <MetaData title={'Programs - Talent Growth'}/>
      <section className="talent-hero-section">
        <div className="max-width-1440">
          <div className="talent-growth">
            <div className="talent-left">
              <img src={TalentGrowthLogo} alt="" className="talent-logo" />
              <h1 className="talent-head">Join our <span className="primary-color">Creative </span> <span>Team</span> <img src={TalentImg1} alt="" className="talentImg1" /></h1>
              <p className="talent-para">Get the opportunity and advance your career. Our Talent Growth Program offers internships to aspiring youths, allowing them to develop and refine their skills while also providing them with potential employment opportunities. </p>
              <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfeusC05CQwew1y5MCVPWui1mqLU9JCIPRVGkQAO8VuIx6Siw/viewform?usp=sf_link" target="_blank"><button className="talent-btn">Register Now</button></Link>
              
            </div>
            <div className="talent-right">
                <img className="hero-img" src={HeroSectionImg} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="about-talent-growth-section">
        <div className="max-width-1440">
          <div className="about-talent-growth">
            <h1 className="about-talent-growth-heading"><span className="primary-color">About</span> Talent Growth Program</h1>
            <p className="about-talent-growth-para">Our Talent Growth Program is designed to identify and nurture talented individuals, and provide them with the opportunity to develop their skills and pursue their career goals. </p>
            <div className="about-talent-growth-cards">
              <div className="about-talent-growth-cards-top">
                <InternsCard internImg={InternImg1} internPosition="React Developer" internName="Hasnain Abbas" internBatchNo="Batch 02" />
                <InternsCard internImg={InternImg2} internPosition="UIUX Designer" internName="Mehak Kanwal" internBatchNo="Batch 02" />
              </div>
              <div className="about-talent-growth-cards-bottom">
                <InternsCard internImg={InternImg3} internPosition="React Developer" internName="Muhammad Ali" internBatchNo="Batch 02" />
                <InternsCard internImg={InternImg4} internPosition="Android Developer" internName="Tajwar Ali Taj" internBatchNo="Batch 02" />
              </div>
            </div>

            <div className="about-talent-slider">
            <Swiper id='testinomial-slider'
              style={{ "overflow": "visible" }}
              slidesPerView={1}
              spaceBetween={950}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              className="mySwiper"
            >
              <SwiperSlide><InternsCard internImg={InternImg1} internPosition="React Developer" internName="Hasnain Abbas" internBatchNo="Batch 02" /></SwiperSlide>
              <SwiperSlide><InternsCard internImg={InternImg2} internPosition="UIUX Designer" internName="Mehak Kanwal" internBatchNo="Batch 02" /></SwiperSlide>
              <SwiperSlide><InternsCard internImg={InternImg3} internPosition="React Developer" internName="Muhammad Ali" internBatchNo="Batch 02" /></SwiperSlide>
              <SwiperSlide><InternsCard internImg={InternImg4} internPosition="Android Developer" internName="Tajwar Ali Taj" internBatchNo="Batch 02" /></SwiperSlide>
            </Swiper>
            
            </div>
          </div>
        </div>
      </section>

      <section className="talent-growth-cards">
        <div className="max-width-1440">
          <h1 className="talent-cards-heading">Our <span className="primary-color">Acheivements</span></h1>
          <div className="talent-cards">
            <TalentGrowthCard talentGrowthTopImg={CertificationsImg} talentGrowthNumbers="600+" cardFirstHead="Successfull" cardSecondHead="Certifications" />

            <TalentGrowthCard talentGrowthTopImg={InternshipImg} talentGrowthNumbers="8+" cardFirstHead="Total Offered" cardSecondHead="Internships" />

            <TalentGrowthCard talentGrowthTopImg={ProjectsImg} talentGrowthNumbers="3k+" cardFirstHead="Completed" cardSecondHead="Projects" />

            <TalentGrowthCard talentGrowthTopImg={CoursesImg} talentGrowthNumbers="6+" cardFirstHead="Total Offered" cardSecondHead="Courses" />
          </div>
        </div>
      </section>

      <section className="talent-growth-trainees-section">
        <div className="max-width-1440">
          <div className="talent-growth-trainees">
            <h1 className="talent-trainees-heading">Thoughts and Feedback of our Talent Growth Program team</h1>
            
            <div className="talent-growth-tranees-bottom">
              <Swiper
                slidesPerView={1}
                spaceBetween={100}
                loop={true}
                centeredSlides={true}
                autoplay={{
                  delay: 3000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                navigation={true}
                modules={[Autoplay, Navigation]}
                className="mySwiper"
              >
                <SwiperSlide><TalentGrowthTraineeCard talentTraineeImage={TraineesImg} TalentTaineePara="We are delighted to share with you the thoughts and feedback of our Talent Growth Program team about our company. These individuals have joined us through our Talent Growth Program, which allows them to develop their skills and grow their careers with us.
" TalentTraineeName="Ahtisham Niaz" TalentTraineePosition="UIUX Designer" /></SwiperSlide>

                <SwiperSlide><TalentGrowthTraineeCard talentTraineeImage={TraineesImg} TalentTaineePara="I recently completed the Step Up course and working as an intern through Talent Growth program and it was a fantastic experience. The mentors of the program were very supportive and acknowledgeable." TalentTraineeName="Ahtisham Niaz" TalentTraineePosition="UIUX Designer" /></SwiperSlide>
                
              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="talent-slider-section">
        <div className="max-width-1440">
          <div className="talent-slider">
            <h1 className="talent-slider-heading">Our <span className="primary-color">Partner </span>Companies</h1>
            <Swiper
              breakpoints={
                {
                  970: {
                    // width:950,
                    slidesPerView: 5
                  },
                  800: {
                    // width:950,
                    slidesPerView: 3
                  },
                  350: {
                    // width:950,
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },

                }
              }
              spaceBetween={50}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              navigation={true}
              modules={[Autoplay, Navigation]}
              className="mySwiper talent-parthers-slider"

            >
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={GBIT}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={AP}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={AKRSP}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={SCO}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={DoroyouInn}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={OneA}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={APEX}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={KIU}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Meredith}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Tmi}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Journal}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Stenovate}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={AlWaliyy}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Tennis}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Arctica}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={MoonAlgo}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Rupani}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={BTF}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={TheKarakoram}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={GDG}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={KamalEnergy}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={GbInvest}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Dum}  />
              </SwiperSlide>
              <SwiperSlide>
                  <TalentGrowthPartners talentPartnerLogo1={Olright}  />
              </SwiperSlide>

            </Swiper>

          </div>
        </div>
      </section>
    </>
  )
}

export default TalentGrowth