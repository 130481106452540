import React, { createRef, useRef, useState } from "react";
import "../ourTeamCard/teamCard.css";
import Mohsin from "../../pages/About/About-Imagess/mohsin.png";
import linkedInIcon from "../../pages/About/About-Imagess/linkedin.png";
import upworkIcon from "../../pages/About/About-Imagess/pngegg (1).png";
import { EmployesData } from "../Api/types";

// interface TeamCardData{
//   teamMemberPhoto:string;
//   memberName:string;
//   memberPosition:string;
//   memberDetail:string;
//   link:string;
// }

// interface TeamCardProps{
//   data:TeamCardData;
//   index?: number;
//   // elementsRef: React.RefObject<HTMLElement>
// }
interface TeamCardProps {
  data: EmployesData;
}

const TeamCard: React.FC<TeamCardProps> = ({ data }) => {
  // console.log(data)
  const sizeRef = useRef(window.innerWidth);

  // console.log(sizeRef)
  const [size, setSize] = useState(sizeRef.current);

  window.addEventListener("resize", function () {
    setSize(window.innerWidth);
  });

  return (
    <>
      <div className="team-card">
        <div className="bod-image">
          <img src={data.imageUrl} alt="" />
        </div>
        <div className="team-member-details">
          <span className="team-member-name">{data.name}</span>
          <span className="team-member-position">{data.designation}</span>
          <p className="team-member-detail">
            {data.description}{" "}
            <button className="seeMore-btn primary-color">See More</button>
          </p>
          <div className="member-social-account">
            <span className="followat">Follow at</span>
            <a href={data.linkdInProfileUrl} target="_blank" rel="noreferrer">
              <img
                className="teamcard-linkedin-img"
                src={linkedInIcon}
                alt=""
              />
            </a>
            <a href={data.upworkProfileUrl} target="_blank" rel="noreferrer">
              <img className="teamcard-upwork-img" src={upworkIcon} alt="" />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamCard;
