import React, { useState, useRef } from "react";
import "./contact.css";
import ReCAPTCHA from "react-google-recaptcha";
import ButtonLoader from "../ButtonLoader/ButtonLoader";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useMutation } from "@tanstack/react-query";
import { postFormData } from "../Api/PostServices";
import { useForm } from "react-hook-form";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from "react-toastify";

const Contact: React.FC = () => {
  const form = useRef<HTMLFormElement | null>(null);
  const [verified, setVerified] = useState<string | null>("");
  const formSchema = z.object({
    name: z
      .string()
      .min(1, "Please Enter name")
      .max(50, "Name should not be greater than 50 characters."),
    email: z
      .string()
      .min(1, "Please enter email")
      .email()
      .max(30, "Email should not be greater than 30 characters."),
    message: z.string().min(1, "Please enter message"),
    recaptchaToken: z.string(),
  });
  type contactFormSchema = z.infer<typeof formSchema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<contactFormSchema>({
    resolver: zodResolver(formSchema),
  });

  const { mutate, isPending, } = useMutation({
    mutationFn: postFormData,
    onSuccess: () => {
      toast.success("Form Submitted Successfully", {
        position: 'top-right',
      });
      reset();
    },
    onError: (error) => {
      toast.error("Failed to submit the form! Error occured",{
        position:'top-right'
      })
      console.log("error accured ", error.message);
    },
  });

  const onSubmit = (data: contactFormSchema) => {
    console.log("Form data to be sent:", data);
    mutate(data);
  };
  const onChangeReCapcha = (token: string | null) => {
    console.log("ReCAPTCHA value:", token);
    setVerified(token);
    setValue("recaptchaToken", token as string);
  };
  return (
    <div className="contact-container">
      <div className="max-width-1440">
        <div className="contactUs">
          <div className="contactUs-left">
            <h1 className="contactUs-heading">
              Have a <span className="primary-color">project </span> in Mind?
            </h1>
            <p className="contactUs-para">
              Do you have an idea and you want to work on it? Let us know what
              services do you need?
            </p>
          </div>
          <div className="contactUs-right">
            <h1 className="contactUs-form-heading">Contact Us</h1>
            <form
              ref={form}
              className="contactUs-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="contact-label-input">
                <label htmlFor="name">
                  Name <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Your Full Name"
                  id="name"
                  {...register("name")}
                />
                {errors.name && <p className="error">{errors.name.message}</p>}
              </div>
              <div className="contact-label-input">
                <label htmlFor="email">
                  Email Address <span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter your Email"
                  {...register("email")}
                  id="email"
                  name="email"
                />
                {errors.email && (
                  <p className="error">{errors.email.message}</p>
                )}
              </div>
              <div className="contact-label-input">
                <label htmlFor="message">
                  Message <span>*</span>
                </label>
                <textarea
                  cols={30}
                  rows={10}
                  placeholder="Let us know  what services do you need."
                  {...register("message")}
                  id="message"
                  name="message"
                ></textarea>
                {errors.message && (
                  <p className="error">{errors.message.message}</p>
                )}
              </div>
              <div>
                <ReCAPTCHA
                  sitekey="6LeL630qAAAAAD-0XXULji8joOLOOPt7IELYuLyJ"
                  onChange={onChangeReCapcha}
                />
                {errors.recaptchaToken && (
                  <p className="error">{errors.recaptchaToken.message}</p>
                )}
              </div>

              <button className="btn-submit" type="submit" disabled={!verified}>
                {isPending ? <ButtonLoader /> : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
