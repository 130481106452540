import React from 'react';

import './App.css';
import Navbar from './Components/Navbar/Navbar';
import Home from './pages/Home/Home';
import Footer from './Components/Footer/Footer';
import AboutUs from './pages/About/About';
import StepUp from './pages/Programs/StepUp';
import TalentGrowth from './pages/Programs/TalentGrowth';
import DigiHunar from './pages/Products/DigiHunar';
import Hotela from './pages/Products/Hotela';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './Components/ScrollToTop/ScrollToTop';
import CareersPage from './pages/CareersPage/CareersPage';
import PageNotFound from './Components/PageNotFound/PageNotFound';
import Contact from './Components/Contact/Contact';
import FormSubmit from './Components/FormSubmit/FormSubmit';
import JobApplicationForm from './pages/CareersPage/JobApplicationForm/JobApplicationForm';
import { useState } from 'react';

function App() {
  const [showModal, setShowModal] = useState(true);

  return (
   
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route
            path="/"
            element={<Home showModal={showModal} setShowModal={setShowModal} />}
          />
          <Route
            path="/about"
            element={<AboutUs showModal={showModal} setShowModal={setShowModal} />}
          />
          <Route path="/products/digihunar" element={<DigiHunar />} />
          <Route path="/products/hotela" element={<Hotela />} />
          <Route path="/programs/stepup" element={<StepUp />} />
          <Route path="/programs/talent-growth" element={<TalentGrowth />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact/success" element={<FormSubmit />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/job-application/:id" element={<JobApplicationForm />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    
  );
}

export default App;
