import React from "react";
import "../CareersPage.css";
import startTime from "../../CareersPage/timer-start.png";
import endTime from "../../CareersPage/timer-pause.png";
import briefCase from "../../CareersPage/briefcase.png";
import locationIcon from "../../CareersPage/location.png";
import { useState } from "react";
import { useRef } from "react";
import { Link } from "react-router-dom";
import "../CareersPage.css";
import { CareerData } from "../../../Components/Api/types";

interface JobCardProps {
  data: CareerData;
}

const JobCard: React.FC<JobCardProps> = ({ data }) => {
  const [date, setDate] = useState(0);
  const heightRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(false);
  const handleLearnMore = () => {
    if (heightRef.current) {
      if (
        heightRef.current.style.maxHeight.length === 0 ||
        heightRef.current.style.maxHeight === "0px"
      ) {
        setHeight(true);
        heightRef.current.style.maxHeight = "1000px";
      } else {
        setHeight(false);
        heightRef.current.style.maxHeight = "0px";
      }
    }
  };

  const formatDate = (dateString?: string | Date) =>
    dateString ? new Date(dateString).toDateString() : "N/A";

  const getDaysAgo = (dateString?: string | Date) => {
    if (!dateString) return "N/A";
    const date = new Date(dateString);
    const diff = Math.ceil(
      (new Date().getTime() - date.getTime()) / (1000 * 60 * 60 * 24)
    );
    return `${diff} day${diff > 1 ? "s" : ""} ago`;
  };

  const isDeadlinePassed = (deadline?: Date) =>
    deadline ? new Date() > new Date(deadline) : false;
  return (
    <div>
      <div className="careers-jobs-div">
        {/* ---- the below code is for mobile responsive ------ */}

        <div className="career-jobs-date-deadline">
          <span className="career-job-posted-day">
            Posted: {getDaysAgo(data.createdAt)}
          </span>
          <span className="career-job-post-deadline">
            Deadline:{" "}
            <span
              style={{
                color: `${isDeadlinePassed(data.deadline) ? "red" : "#9D9B9B"}`,
              }}
            >
              {formatDate(data.deadline)}
            </span>{" "}
          </span>
        </div>

        {/* ----- above code is for mobile responsive ------- */}
        <div className="careers-jobs-top">
          <div className="careers-jobs-top-left">
            <div className="careers-jobs-posted">
              <span className="job-titles">Posted</span>
              <div className="icon-day-time">
                <div className="time-date-icon-div">
                  <img src={startTime} alt="" />
                </div>
                <div className="career-job-details career-job-day-posted">
                  {getDaysAgo(data.createdAt)}
                </div>
              </div>
            </div>
            <div className="careers-jobs-deadline">
              <span className="job-titles">Deadline</span>
              <div className="icon-day-time">
                <div className="time-date-icon-div">
                  <img src={endTime} alt="" />
                </div>
                <div
                  className="career-job-details"
                  style={{
                    color: `${
                      isDeadlinePassed(data.deadline) ? "red" : "#9D9B9B"
                    }`,
                  }}
                >
                  {formatDate(data.deadline)}
                </div>
              </div>
            </div>
          </div>
          <div className="careers-jobs-top-right">
            <div className="careers-job-type">
              <span className="job-titles dsply-none">Type</span>
              <div className="icon-day-time">
                <div className="time-date-icon-div">
                  <img src={briefCase} alt="" />
                </div>
                <span className="career-job-details">{data.job_type}</span>
              </div>
            </div>
            <div className="career-job-location">
              <span className="job-titles dsply-none">Location</span>
              <div className="icon-day-time">
                <div className="time-date-icon-div">
                  <img src={locationIcon} alt="" />
                </div>
                <span className="career-job-details">{data.location}</span>
              </div>
            </div>
          </div>
        </div>

        <h1 className="careers-jobs-heading">
          {data.job_title} ({data.job_positions} Positions)
        </h1>
        <div className="careers-jobs-description">
          <h1 className="careers-jobs-para">
            <span className="careers-ideometrix">{data.headline}</span>{" "}
            {data.description}
          </h1>
          <div className="height-container" ref={heightRef}>
            {data.job_description?.map((res, index) => {
              return (
                <div key={index}>
                  <span className="careers-ideometrix">{res.heading}</span>
                  <ul className="career-requirement-list">
                    {res.heading_description.map((item, subIndex) => {
                      console.log(item);
                      return (
                        <li className="requirements-lists" key={subIndex}>
                          {item.item}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <h1 className="careers-jobs-para">{data.encouragement}</h1>

          {/* end */}
        </div>

        {/* ----- the below code is for mobile ----- */}

        <div className="career-job-time-city">
          <div className="icon-day-time">
            <div className="time-date-icon-div">
              <img src={briefCase} alt="" />
            </div>
            <span className="career-job-details">{data.job_type}</span>
          </div>

          <div className="icon-day-time">
            <div className="time-date-icon-div">
              <img src={locationIcon} alt="" />
            </div>
            <span className="career-job-details">{data.location}</span>
          </div>
        </div>

        {/* ------ The above code is for mobile ----- */}
        <div className="careers-jobs-bottom-btns">
          <Link to={`/job-application/${data._id}`}>
            <button
              className={`${
                isDeadlinePassed(data.deadline)
                  ? "careers-apply-btn-disabled"
                  : "careers-apply-now-btn"
              }`}
              disabled={isDeadlinePassed(data.deadline)}
              
            >
              {" "}
              {isDeadlinePassed(data.deadline) ? (
                <p>Job Closed</p>
              ) : (
                <p>Apply Now</p>
              )}{" "}
            </button>
          </Link>

          <button
            className="careers-learn-more primary-color"
            onClick={handleLearnMore}
          >
            {height ? "See Less" : "Learn More"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobCard;
