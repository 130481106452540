import React from 'react'
import './HomeRecentWork.css'
import { Portfolio } from '../Api/types'

interface HomeRecentWorkProps{
 data: Portfolio;
}

const HomeRecentWork:React.FC<HomeRecentWorkProps> = ({data}) => {
  return (
    <>
        <div className="recent-work-card">
            <img loading='lazy' className='recent-work-card-head' src={data.imageUrl} alt="" />
            <h1 className="recent-work-card-heading">{data.title}</h1>
        </div>
    </>
  )
}

export default HomeRecentWork