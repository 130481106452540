import { useEffect, useRef, useState } from "react";
import "../CareersPage/CareersPage.css";
import startTime from "../CareersPage/timer-start.png";
import endTime from "../CareersPage/timer-pause.png";
import briefCase from "../CareersPage/briefcase.png";
import locationIcon from "../CareersPage/location.png";
import JobCard from "./JobCard/JobCard";
import jobData from "./jobData";
import { useQuery } from "@tanstack/react-query";
import { CareerData } from "../../Components/Api/types";
import { GetAllCareers } from "../../Components/Api/PostServices";
import { RotatingLines } from "react-loader-spinner";

const CareersPage = () => {
  const heightRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(false);
  const [date, setDate] = useState(0);

  const handleLearnMore = () => {
    if (heightRef.current) {
      if (
        heightRef.current.style.maxHeight.length === 0 ||
        heightRef.current.style.maxHeight === "63px"
      ) {
        setHeight(true);
        heightRef.current.style.maxHeight = "676px";
      } else {
        setHeight(false);
        heightRef.current.style.maxHeight = "63px";
      }
    }
  };

  const { data, isLoading } = useQuery<CareerData[]>({
    queryFn: GetAllCareers,
    queryKey: ["careers"],
  });
  console.log("data", data);
  return (
    <>
      <section className="careers-hero-section">
        <div className="max-width-1440">
          <div className="careers-hero">
            <span className="carrers-apply-now">
              Apply now to join our diverse team and work with us.
            </span>
            <h1 className="careers-hero-heading">
              We believe in <span className="primary-color">diversity </span>{" "}
              and we work with it
            </h1>
          </div>
        </div>
      </section>
      {isLoading ? (
        // <p style={{ textAlign: "center" }}>Loading...</p>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: "center",top: '300px',  marginBottom: '100px'}}>
          <RotatingLines
            strokeColor="#26344E"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : data && data.length > 0 ? (
        <section className="current-openings-section">
          <div className="max-width-1440">
            <div className="careers-current-openings">
              <h1 className="careers-current-openings-head">
                Current Openings
              </h1>
              {data.map((job) => (
                <JobCard key={job._id} data={job} />
              ))}
            </div>
          </div>
        </section>
      ) : (
        <p style={{ textAlign: "center", marginBottom: "10px" }}>
          No Current openings
        </p>
      )}
    </>
  );
};

export default CareersPage;
