import React, { useRef, useState } from "react";
import "../About/About.css";
import AboutUsImg from "../About/About-Imagess/AboutUs-img.png";
import TeamCard from "../../Components/ourTeamCard/TeamCard";
import EmployeeCard from "../../Components/EmployeesCards/EmployeeCard";

import { HiOutlineChevronDown } from "react-icons/hi";
import { HiOutlineChevronUp } from "react-icons/hi";
import MetaData from "../../Components/MetaData";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAllEmployees } from "../../Components/Api/PostServices";
import { EmployesData } from "../../Components/Api/types";

interface AboutProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const About: React.FC<AboutProps> = () => {
  const heightRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(false);
  const handleSeeMore = () => {
    if (heightRef.current) {
      if (
        heightRef.current.style.maxHeight.length === 0 ||
        heightRef.current.style.maxHeight === "1043px"
      ) {
        setHeight(true);
        heightRef.current.style.maxHeight = "3000px";
      } else {
        setHeight(false);
        heightRef.current.style.maxHeight = "1043px";
      }
    }
  };

  const { data } = useQuery<EmployesData[]>({
    queryFn: getAllEmployees,
    queryKey: ["employees"],
  });

  return (
    <>
      <MetaData title={"About Us"} />
      <div className="aboutUs-section">
        <div className="max-width-1440">
          <div className="aboutUs">
            <img src={AboutUsImg} alt="" />
          </div>
        </div>
      </div>

      <div className="max-width-1440">
        <div className="aboutUs-content">
          <h1 className="aboutUs-heading">
            <span className="primary-color">About</span> Us
          </h1>
          <div className="aboutUs-paragraphs">
            <p>
              Realizing the need for human-centric and Client-oriented solutions
              IdeoMetriX Pvt. Ltd. was founded on 8 December 2020. It is a
              digital media agency with its physical location in Gilgit,
              Pakistan. The company provides custom software development, mobile
              app development, web app development, digital media services,
              visual and print media solutions to emerging brands and <br />
              The company operates with a unique business model, in which a
              local team of 25 members with expertise in different domains
              handles marketing and communication, and Quality Assurance. While
              a pool of 15+ freelancers from all around Pakistan works remotely,
              on a project basis. This model not only provides a work
              opportunity to unemployed youth and University students with
              skills in various domains but also gives an opportunity of
              extra-earning for low-income 9-5 jobholders. Moreover, it also
              assures the company as the projects are already led by experienced
              professionals and domain experts. <br />
              In merely 6 months of its inception, the company already holds a
              portfolio of working with local stakeholders like Aga Khan Rural
              Support Programe (AKRSP), Accelerate Prosperity, Rupani
              Foundation, Karakoram International University, OEC, SCO, and many
              more. The team has so far completed more than 100+ projects.
            </p>
          </div>
          <div className="aboutUs-paragraphs-2">
            <p className="aboutUs-para2">
              Realizing the need for human-centric and Client-oriented solutions
              IdeoMetriX Pvt. Ltd. was founded on 8 December 2020. It is a
              digital media agency with its physical location in Gilgit,
              Pakistan. The company provides custom software development, mobile
              app development, web app development, digital media services,
              visual and print media solutions to emerging brands and and
              organizations. The company operates with a unique business model,{" "}
            </p>
            <Link to={"#"} className="primary-color">
              Read More
            </Link>
          </div>
        </div>
      </div>

      {/* ----------- Our Team Section  ------------- */}

      <div className="ourTeam-section">
        <div className="max-width-1440">
          <div className="ourTeam">
            <h1 className="ourTeam-heading">
              Our <span className="primary-color">Team</span>
            </h1>
            {!data ? (
              <p style={{ textAlign: "center" }}>
                No team or employee data to show
              </p>
            ) : (
              <>
                {data && data.length > 0 ? (
                  <div className="ourTeam-cards">
                    {data
                      .filter((item) => item.type === "bod")
                      .map((bod) => (
                        <TeamCard data={bod} key={bod._id} />
                      ))}
                  </div>
                ) : (
                  <p style={{ textAlign: "center" }}>
                    No employee data to show
                  </p>
                )}
                {data && data.length > 0 ? (
                  <div className="ourEmployee-cards" ref={heightRef}>
                    {data
                      .filter((item) => item.type === "employee")
                      .map((employee, index) => (
                        <EmployeeCard data={employee} key={employee._id} />
                      ))}
                  </div>
                ) : (
                  <p style={{ textAlign: "center" }}>no employee to show</p>
                )}
              </>
            )}

            <button
              style={{ marginTop: `${height ? "30px" : "0"}` }}
              className="ourTeam-card-btn"
              onClick={handleSeeMore}
            >
              {height ? "See Less" : "See All"}{" "}
              {height ? (
                <HiOutlineChevronUp className="OurTeam-btn" />
              ) : (
                <HiOutlineChevronDown className="OurTeam-btn" />
              )}{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
