import React from 'react'
import './DigiHunar.css'

// Importing Components
import DigiCoursesCard from '../../Components/DigiHunarCards/DigiCoursesCard'
import DigiTestimonialCard from '../../Components/DigiHunarCards/DigiTestimonialCard'

// Importing Images
// import DigiBg from './DigiHunarImages/DigiHeroBg.png'
import DigiHunarLogo from './DigiHunarImages/logoDigihunar.svg'
import GroupImg1 from './DigiHunarImages/groupImg1.png'
import DigiHeroCard from '../../Components/DigiHunarCards/DigiHeroCard'
import HeroCardImg1 from './DigiHunarImages/rightcareer1.png'
import HeroCardImg2 from './DigiHunarImages/rightcareer2.png'
import HeroCardImg3 from './DigiHunarImages/rightcareer3.png'
import HeroCardImg4 from './DigiHunarImages/rightcareer4.png'
import HeroCardImg5 from './DigiHunarImages/career1.png'
import HeroCardImg6 from './DigiHunarImages/career2.png'
import HeroCardImg7 from './DigiHunarImages/career3.png'
import HeroCardImg8 from './DigiHunarImages/career4.png'

import Zeppelin from './DigiHunarImages/zeppelin.png'
import Oracle from './DigiHunarImages/oracle.png'
import Morpheus from './DigiHunarImages/morpheus.png'
import Samsung from './DigiHunarImages/samsung.png'
import Monday from './DigiHunarImages/monday.png'
import Segment from './DigiHunarImages/segment.png'
import Protonet from './DigiHunarImages/protonet.png'

// Courses Images
import CourseImg1 from './DigiHunarImages/webdev.png'
import CourseImg2 from './DigiHunarImages/UiUx.png'
import CourseImg3 from './DigiHunarImages/graphics.png'
import DigiLogo from './DigiHunarImages/digiHunar.png'

// Testimonial Images
import TestimonialImg1 from './DigiHunarImages/traineesCard1.png'
import TestimonialImg2 from './DigiHunarImages/traineesCard2.png'




// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/scrollbar";
import "swiper/css/pagination";
import "swiper/css/navigation";

import "../Programs/TalentGrowth.css";

// import required modules
import { Keyboard, Scrollbar, Autoplay, Pagination } from "swiper";
import MetaData from '../../Components/MetaData'



const DigiHunar = () => {
  return (
    <>
      <MetaData title={'Products - Digihunar'} />
      <section className="digihunar-hero-section">
        <div className="max-width-1440">
          <div className="digihunar-hero">
            <div className="digihunar-hero-left">
              <img src={DigiHunarLogo} alt="" className="digihunar-logo" />
              <h1 className="digihunar-hero-heading">Get <span className="primary-color">Creative </span>with DigiHunar</h1>
              <div className="digi-hero-left-card">
                <div className="digi-hero-left-card-imgs">
                  <img src={HeroCardImg5} alt="" className='digi-hero-card-img1' />
                  <img src={HeroCardImg6} alt="" className="digi-hero-card-img2" />
                  <img src={HeroCardImg7} alt="" className="digi-hero-card-img3" />
                  <img src={HeroCardImg8} alt="" className="digi-hero-card-img4" id='extra-styling' />
                </div>
                {/* <DigiHeroCard cardImg1={HeroCardImg5} cardImg2={HeroCardImg6} cardImg3={HeroCardImg7} cardImg4={HeroCardImg8} /> */}
              </div>
              <p className="digihunar-hero-para">Step out of your comfort zone and learn in-demand skills. We are here with a variety of courses for you.</p>
              <a href="https://digihunar.com" target="_blank" rel="noreferrer"><button className="visit-digihunar">Visit Website</button></a>
            </div>

            <div className="digihunar-hero-right">
              <img src={GroupImg1} alt="" className="digihunar-hero-right-img" />
              <div className="digi-hero-right-card">
                <DigiHeroCard cardHeading="12K+ Assisted Students" cardImg1={HeroCardImg1} cardImg2={HeroCardImg2} cardImg3={HeroCardImg3} cardImg4={HeroCardImg4} />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="digihunar-slider-section">
        <div className="max-width-1440">
          <div className="digihunar-slider">
            <h1 className="digihunar-slider-heading">Our <span className="primary-blue-color">Partner </span>Companies</h1>
            <Swiper
              breakpoints={
                {
                  970: {
                    // width:950,
                    slidesPerView: 7
                  },
                  800: {
                    // width:950,
                    slidesPerView: 5
                  },
                  480: {
                    // width:950,
                    slidesPerView: 5
                  },

                  350: {
                    slidesPerView: 5,
                    spaceBetween: 30
                  }

                }
              }
              slidesPerView={7}
              // spaceBetween={0}
              loop={true}
              centeredSlides={true}
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              modules={[Autoplay]}
              className="mySwiper"

            >
              <SwiperSlide>
                <img src={Zeppelin} alt="" className='mbl-responsive' />
              </SwiperSlide>

              <SwiperSlide>
                <img src={Oracle} alt="" className='mbl-responsive' />
              </SwiperSlide>

              <SwiperSlide>
                <img src={Morpheus} alt="" className='mbl-responsive mbl-responsive-87' />
              </SwiperSlide>

              <SwiperSlide>
                <img src={Samsung} alt="" className='mbl-responsive mbl-responsive-78' />
              </SwiperSlide>

              <SwiperSlide>
                <img src={Monday} alt="" className='mbl-responsive mbl-responsive-98' />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Segment} alt="" className='mbl-responsive mbl-responsive-87' />
              </SwiperSlide>
              <SwiperSlide>
                <img src={Protonet} alt="" className='mbl-responsive mbl-responsive-77' />
              </SwiperSlide>
            </Swiper>

          </div>
        </div>
      </section>

      <section className="digi-courses-section">
        <div className="max-width-1440">
          <div className="digi-courses">
            <h1 className="digi-courses-heading"><span className="primary-blue-color">Digital Courses </span>for Beginners and Professionals</h1>
            <p className="digi-courses-para">Learn skills varying from beginner to advanced levels through courses designed by subject matter experts and receive accredited certificates that are recognized by renowned organizations.</p>
            <div className="digi-courses-cards">
              <Swiper
                breakpoints={
                  {
                    1345: {
                      // width:950,
                      slidesPerView: 3
                    },
                    360: {
                      // width:950,
                      slidesPerView: 3,
                      spaceBetween: 0,
                    }

                  }
                }
                slidesPerView={3}
                spaceBetween={25}
                loop={true}
                centeredSlides={false}

                autoplay={{
                  delay: 4000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination, Autoplay]}

                className="mySwiper">
                <SwiperSlide><DigiCoursesCard courseImg={CourseImg1} digiLogo={DigiLogo} courseTitle="Website Development Basics" courseDesc="you will be able to build websites from scratch and understand the basics of web development." courseRatings="4.8  Reviews ( 135 )" /></SwiperSlide>
                <SwiperSlide><DigiCoursesCard courseImg={CourseImg2} digiLogo={DigiLogo} courseTitle="UI/UX Design Fundamentals" courseDesc="Get to know the principles of visual design and how to create interfaces that are both user-friendly and visually appealing." courseRatings="4.8  Reviews ( 120 )" /></SwiperSlide>
                <SwiperSlide><DigiCoursesCard courseImg={CourseImg3} digiLogo={DigiLogo} courseTitle="Graphic Design Fundamentals" courseDesc="Get to know the basics of design principles and learn how to use popular design software such as Adobe Photoshop, Illustrator, and InDesign." courseRatings="4.8  Reviews ( 125 )" /></SwiperSlide>
                <SwiperSlide><DigiCoursesCard courseImg={CourseImg3} digiLogo={DigiLogo} courseTitle="Graphic Design Fundamentals" courseDesc="Get to know the basics of design principles and learn how to use popular design software such as Adobe Photoshop, Illustrator, and InDesign." courseRatings="4.8  Reviews ( 125 )" /></SwiperSlide>
                


              </Swiper>
            </div>
          </div>
        </div>
      </section>

      <section className="digi-testimonials-section">
        <div className="max-width-1440">
          <div className="digihunar-testimonials">
            <h1 className="digi-testimonial-heading">Our Trainees <span className="primary-blue-color">Testimonials</span></h1>
            <p className="digi-testimonial-para">Know what our alumni have to say about our courses.</p>

            <div className="digi-testimonial-slider">

              <Swiper

                slidesPerView={2}
                centeredSlides={false}
                spaceBetween={30}
                loop={true}
                slidesPerGroupSkip={2}
                keyboard={{
                  enabled: true,
                }}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }}
                breakpoints={{
                  360: {
                    slidesPerView: 2,
                    slidesPerGroup: 1,
                    spaceBetween: 5,
                  },
                }}
                pagination={{
                  clickable: true,
                }}
                modules={[Keyboard, Scrollbar, Pagination, Autoplay]}

                className="mySwiper">
                <SwiperSlide><DigiTestimonialCard DigiTestHeading="Superb!" DigiTestPara="I started my freelance career after joining the DigiHunar 2022. They provided us with such an environment where we came to know about our unique skills, they polished my skills and made me able to earn for myself. I highly appreciate the mentor Read More..." DigiTestTraineeImg={TestimonialImg1} TraineeName="Sara Soleh" /></SwiperSlide>
                <SwiperSlide><DigiTestimonialCard DigiTestHeading="Superb!" DigiTestPara="DigiHunar Training platform is something that helps you to boot up your skills to become a successful tech pro. I will appreciate the management for making such an Platform where you’re allowed to learn more and more." DigiTestTraineeImg={TestimonialImg2} TraineeName="Tajwar Ali Taj" /></SwiperSlide>
                <SwiperSlide><DigiTestimonialCard DigiTestHeading="Superb!" DigiTestPara="I started my freelance career after joining the DigiHunar 2022. They provided us with such an environment where we came to know about our unique skills, they polished my skills and made me able to earn for myself. I highly appreciate the mentor Read More..." DigiTestTraineeImg={TestimonialImg1} TraineeName="Sara Soleh" /></SwiperSlide>
                <SwiperSlide><DigiTestimonialCard DigiTestHeading="Superb!" DigiTestPara="DigiHunar Training platform is something that helps you to boot up your skills to become a successful tech pro. I will appreciate the management for making such an Platform where you’re allowed to learn more and more." DigiTestTraineeImg={TestimonialImg2} TraineeName="Tajwar Ali Taj" /></SwiperSlide>



                {/* <SwiperSlide><DigiTestimonialCard DigiTestHeading="Superb!" DigiTestPara="I am grateful to Digihunar for giving me the tools and skills that are required to earn through online platforms. This learning will stay with us for the rest of our lives to become successful online earners." DigiTestTraineeImg={TestimonialImg1} TraineeName="Faryal" /></SwiperSlide>
                <SwiperSlide><DigiTestimonialCard DigiTestHeading="Superb!" DigiTestPara="It was really good and flexible to take the digihunnar courses. I learned everything step by step with perfect guidance and help. I am fully satisfied with the digihunnar course and i highly recommend taking digihunnar courses." DigiTestTraineeImg={TestimonialImg2} TraineeName="Hasnain" /></SwiperSlide> */}



              </Swiper>


            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default DigiHunar