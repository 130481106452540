import React from 'react'
import './SuccessCard.css'

interface SuccessCardProps{
    successzimg:string, 
    successleftheading:string, 
    successleftpara: string, 
    successfiverimg: string, 
    successclheading: string, 
    successclgreenheading:string, 
    successcrleftheading: string, 
    successquoteimg: string, 
    successrightpara:string,
    successResponsh: string,
}

const SuccessCard:React.FC<SuccessCardProps> = ({ successzimg, successleftheading, successleftpara, successfiverimg, successclheading, successclgreenheading, successcrleftheading, successquoteimg, successrightpara,successResponsh }) => {
    return (
        <>
            <div className="success-card">

                <div className="success-card-left">
                    <img className='success-left-img' src={successzimg} alt="" />
                    <h1 className="success-card-left-heading">{successleftheading}</h1>
                    <p className="success-card-left-para">{successleftpara}</p>

                </div>

                <div className="success-card-right">

                    <img className='success-fiver-img' src={successfiverimg} alt="" />

                    <div className="success-center">

                        <div className="success-fh">
                        <h1 className="success-card-clright-heading">{successclheading}</h1>
                        <h1 className="success-card-clgreen-heading">{successclgreenheading}</h1>
                        </div>
                        
                        <h1 className="success-card-crright-heading">{successcrleftheading}</h1>
                    </div>

                    <img className='success-quote-img' src={successquoteimg} alt="" />
                    <p className="success-card-right-para">{successrightpara}</p>

                    <h1 className="success-card-responsive-crright-heading">{successResponsh}</h1>

                </div>
            </div>



        </>
    )
}

export default SuccessCard