import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AddApplicant } from "../../../Components/Api/PostServices";
import "./job.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { Dropzone, ExtFile, FileMosaic } from "@files-ui/react";
import { useParams } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import { BsCheckCircle, BsExclamationOctagon, BsSlash, BsSlashCircle } from "react-icons/bs";
const JobApplicationSchema = z.object({
  applicantName: z.string().min(1, "Applicant name is required"),
  applicantEmail: z.string().email("Invalid email address"),
  applicantPhoneNumber: z
    .string()
    .min(10, "Phone number should be at least 10 characters"),
  portfolioLink: z.string().url("Invalid URL for portfolio link"),
  experienceYears: z.enum(["1", "2", "3", "4", "5+"]),
});

type JobApplicationFormData = z.infer<typeof JobApplicationSchema>;

const JobApplicationForm = () => {
  const queryClient = useQueryClient();
  const [files, setFiles] = useState<ExtFile | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const { id } = useParams();

  console.log("job id:", id);

  const { mutate, isPending } = useMutation({
    mutationFn: AddApplicant,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["applicants"] });
      // toast.success("Applicant added successfully!");
      const successMessage = "Your application has been submitted successfully!";
      setSuccess(successMessage);
    },
    onError: (err: any) => {
      const errorMessage =
        err.response?.data || err.message || "failed. Please try again.";
      setError(errorMessage);
      // toast.error(
      //   `Failed to new Applicant. Please try again. ${
      //     error.response?.data || error.message
      //   }`
      // );
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<JobApplicationFormData>({
    resolver: zodResolver(JobApplicationSchema),
  });

  const onSubmit = (data: JobApplicationFormData) => {
    setError(null);
    console.log("Files in submit: ", files);
    const formData = new FormData();

    // formData.append("job_category", data.job_category);
    if (files) {
      formData.append("applicantName", data.applicantName);
      formData.append("applicantEmail", data.applicantEmail);
      formData.append("applicantPhoneNumber", data.applicantPhoneNumber);
      formData.append("portfolioLink", data.portfolioLink);
      formData.append("experienceYears", data.experienceYears);
      formData.append("file", files.file as Blob);
      // Update the id with the actual career's id from params
      // formData.append('career_id', "677e2a17c40affbae732b41e")
      formData.append("career_id", id || "");
      mutate(formData);
      reset();
    }
  };

  return (
    <div className="job-application-container">
      <h2>Job Application Form</h2>
      <form onSubmit={handleSubmit(onSubmit)} className="job-application-form">
        {error && (
          <div className="applicant-form-error">
            <BsExclamationOctagon/>
            <h1>{error}</h1>
          </div>
        )}
        {success && (
          <div className="applicatant-form-success">
            <BsCheckCircle/>
          <h1>{success}</h1>
        </div>
        )}
        <div className="input-field">
          <label htmlFor="applicantName">
            Name <span>*</span>
          </label>
          <input
            placeholder="Your Full Name"
            id="applicantName"
            {...register("applicantName")}
          />
          <span>{errors.applicantName?.message}</span>
        </div>
        <div className="input-field">
          <label htmlFor="applicantEmail">
            Email <span>*</span>
          </label>
          <input
            id="applicantEmail"
            placeholder="Enter Your Email"
            type="email"
            {...register("applicantEmail")}
          />
          <span>{errors.applicantEmail?.message}</span>
        </div>
        <div className="input-field">
          <label htmlFor="applicantPhoneNumber">
            Phone Number <span>*</span>
          </label>
          <input
            placeholder="Your Phone Number"
            id="applicantPhoneNumber"
            {...register("applicantPhoneNumber")}
          />
          <span>{errors.applicantPhoneNumber?.message}</span>
        </div>
        <div className="input-field">
          <label htmlFor="portfolioLink">
            Portfolio Link <span>*</span>
          </label>
          <input
            placeholder="Your Portfolio Link"
            id="portfolioLink"
            {...register("portfolioLink")}
          />
          <span>{errors.portfolioLink?.message}</span>
        </div>
        <div className="input-field">
          <label htmlFor="experienceYears">
            Experience (Years) <span>*</span>
          </label>
          <select
            className="dropDown"
            id="experienceYears"
            {...register("experienceYears")}
          >
            <option value="">Select experience</option>
            <option value="1">1 year</option>
            <option value="2">2 years</option>
            <option value="3">3 years</option>
            <option value="4">4 years</option>
            <option value="5+">5 years</option>
          </select>
          <span>{errors.experienceYears?.message}</span>
        </div>
        <div className="input-field">
          <label htmlFor="resumeFile">Resume</label>
          <Dropzone
            onChange={(newFiles) => {
              console.log("Selected files: ", newFiles);
              setFiles(newFiles[0]);
            }}
            value={files ? [files] : []}
            accept="application/pdf"
            maxFiles={1}
            maxFileSize={5 * 1024 * 1024}
            className="File-picker"
          >
            <FileMosaic {...files} preview />
          </Dropzone>
          {/* <span>{errors.resumeLink?.message}</span> */}
        </div>
        <button type="submit" className="submit-btn">
          {isPending ? (
            <RotatingLines
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>
  );
};

export default JobApplicationForm;
